<template>
    <div>
        <div class="card card-custom gutter-b">
            <div class="card-header">
                <div class="card-title">
                <h3 class="card-label">Profil Organisasi Pengusaha</h3>
                </div>
            </div>
            <div class="card-body">
                <!--begin::Example-->
                <div class="example mb-10">
                    <h4 class="mb-10 font-weight-bold text-dark">Informasi Organisasi Pengusaha</h4>
                <!--begin::Input-->
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Nama Organisasi</label>
                        <div class="col-lg-3">
                            <input type="text" v-model="profil.nama_organisasi" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Alamat Kantor</label>
                        <div class="col-lg-3">
                            <input type="text" v-model="profil.alamat" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Alamat Surat Elektronik (email)</label>
                        <div class="col-lg-8">
                            <input type="email" v-model="profil.email" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Ketua Organisasi</label>
                        <div class="col-lg-8">
                            <input type="text" v-model="profil.nama_ketua" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Nomor seluler</label>
                        <div class="col-lg-8">
                            <input type="text" v-model="profil.nomor_seluler" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Jumlah Anggota Aktif</label>
                        <div class="col-lg-8">
                            <input type="number" v-model="profil.jumlah_anggota" class="form-control"/>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-4">Kepengurusan</label>
                        <div class="col-3">
                            <b-form-select v-model="profil.kepengurusan" :options="kepengurusan" style="width: 230px;"></b-form-select>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-4">Program kerja</label>
                        <div class="col-3">
                            <b-form-select v-model="profil.program_kerja" :options="proker" style="width: 230px;"></b-form-select>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-4">Koordinasi dengan PTSP</label>
                        <div class="col-3">
                            <b-form-select v-model="profil.koordinasi_ptsp" :options="koordinasi" style="width: 230px;"></b-form-select>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-4">Kegiatan dengan PTSP Pemda</label>
                        <div class="col-3">
                            <b-form-select v-model="profil.kegiatan_ptsp" :options="kegiatan" style="width: 230px;"></b-form-select>
                        </div>
                </div>
                <div class="form-group row">
                    <label class="col-4">Koordinasi dengan PTSP Pemda terakhir kali</label>
                        <div class="col-3">
                            <b-form-select v-model="profil.koordinasi_terakhir" :options="koordinasiterakhir" style="width: 230px;"></b-form-select>
                        </div>
                </div>
                <button type="button" v-if="user.id_role != 'helpdesk' || user.id_role != 'admin_helpdesk'" @click="update" style="float: right;" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"><i class="fas fa-save"></i>Simpan</button>
                </div>
                <!--end::Example-->
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
// @import "vue-select/src/scss/vue-select.scss";

export default {
  name: "Profil-Asosiasi",
  components: {vSelect},
  data() {
    return {
    url: localStorage.getItem('baseapi'),
    token: localStorage.getItem('id_token'),
    idprofil: localStorage.getItem('idprofil'),
    user: JSON.parse(localStorage.getItem('user')),
    tanggal: localStorage.getItem('tanggal'),
    status:[],
    //   tabIndex: 0
    idptsp:'',
    nama_organisasi:'',
    alamat:'',
    nama_ketua:'',
    jumlah_anggota:'',
    nomor_seluler:'',
    email:'',
    selectedkepengurusan: null,
        kepengurusan: [
          { value: null, text: 'Silahkan Pilih' },
          { value: 'Ada, aktif', text: 'Ada, aktif' },
          { value: 'Ada, kurang aktif', text: 'Ada, kurang aktif' },
          { value: 'Ada, tidak aktif', text: 'Ada, tidak aktif' },
        ],
        selectedproker: null,
        proker: [
          { value: null, text: 'Silahkan Pilih' },
          { value: 'Ada, berjalan', text: 'Ada, berjalan' },
          { value: 'Ada, kurang berjalan', text: 'Ada, kurang berjalan' },
          { value: 'Ada, tidak berjalan', text: 'Ada, tidak berjalan' },
        ],
        selectedkoordinasi: null,
        koordinasi: [
          { value: null, text: 'Silahkan Pilih' },
          { value: 'Ada, rutin', text: 'Ada, rutin' },
          { value: 'Ada, tidak rutin', text: 'Ada, tidak rutin' },
          { value: 'Tidak pernah', text: 'Tidak pernah' },
        ],
        selectedkegiatan: null,
        kegiatan: [
          { value: null, text: 'Silahkan Pilih' },
          { value: 'Ada, banyak', text: 'Ada, banyak' },
          { value: 'Ada, sedikit', text: 'Ada, sedikit' },
          { value: 'Tidak ada', text: 'Tidak ada' },
        ],
        selectedkoordinasiterakhir: null,
        koordinasiterakhir: [
          { value: null, text: 'Silahkan Pilih' },
          { value: '1 bulan terakhir', text: '1 bulan terakhir' },
          { value: '6 bulan terakhir', text: '6 bulan terakhir' },
          { value: 'Lebih dari 6 bulan yang lalu', text: 'Lebih dari 6 bulan yang lalu' },
        ],
      profil:[],
    };
  },

  mounted() {
    // set the tab from previous
    // this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profil Asosiasi" }]);
  },
  methods: {
    update(){
      axios
        .post(
          this.url+`/profil_asosiasi/`+this.idprofil,
          {
            nama_organisasi:this.profil.nama_organisasi,
            alamat:this.profil.alamat,
            email:this.profil.email,
            nama_ketua:this.profil.nama_ketua,
            nomor_seluler:this.profil.nomor_seluler,
            jumlah_anggota:this.profil.jumlah_anggota,
            kepengurusan:this.profil.kepengurusan,
            program_kerja:this.profil.program_kerja,
            koordinasi_ptsp:this.profil.koordinasi_ptsp,
            kegiatan_ptsp:this.profil.kegiatan_ptsp,
            koordinasi_terakhir:this.profil.koordinasi_terakhir,
          },{
            headers: {
                "Content-type": "application/json",
                "xth": this.token,
                }
            }
        )
        .then((res) => {
            this.$bvToast.toast('Berhasil Disimpan', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            this.loaddata()
          return res;
        })
        .catch((err) => {
          console.log(err)
          this.$bvToast.toast('Gagal Disimpan', {
          title: `Failed `,
          variant: `danger`,
          solid: true
        })
          return err;
        });
    },
    loaddata(){
        axios.get(this.url+`/tanggal_kegiatans?filter=tanggal_mulai,<=,`+this.tanggal+`;tanggal_selesai,>=,`+this.tanggal, {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
            this.status = response.data.data;
        });

        // /profil_asosiasi
        axios.get(this.url+`/profil_asosiasi?id=`+this.idprofil, {
          headers: {
              'xth': this.token
          }
        }).then(response => {
        this.profil = response.data.data;
        }).catch(error=>{
        console.log(error)
        return error
        });
    }
  },
  created(){
      this.loaddata();
  }
};
</script>